import * as React from 'react'

import Page from '../components/layouts/Page'
import Container from '../components/layouts/Container'
// @ts-ignore
import logo from '../images/Logo.svg'
// @ts-ignore
import background from '../images/form-bubble-intro.svg'
import GoToMapButton from '../components/projects/GoToMapButton'
import styled from '../styles/styled'
import LocalizedButton from '../components/Button'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { HomeQuery } from '../graphql-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'

interface Props {
  data: HomeQuery
}

const StyledGoToMapButton = styled(GoToMapButton)`
  z-index: 10;
  position: absolute;
  bottom: 10%;
  right: 15%;
`

const StyleContainer = styled(Container)`
  text-align: center;
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IntroText = styled.div`
  width: 100%;
  max-width: 580px;
  height: 330px;
  overflow: visible;
  background: url(${background}) no-repeat top center;
  background-size: 90% auto;
  padding: 40px 90px;
  margin: 0 auto;
  animation: bgani 5s infinite ease-out, bgmove 5s infinite ease-out;
`

const IntroButton = styled(LocalizedButton)`
  display: inline-block;
  background: none;
  border: 1px solid;
  margin-top: 20px;
  color: ${props => props.theme.colors.white};
`

const IndexPage: React.FC<Props> = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Page>
        <StyleContainer>
          <h1
            css={{
              fontSize: '65px',
              color: '#fff',
              marginBottom: '1rem'
            }}
          >
            EXPLORE
          </h1>
          <img
            src={logo}
            alt="ClimateHealthMap"
            css={{
              height: '40px',
              width: 'auto',
              marginBottom: '1rem'
            }}
          />
          <IntroText>
            <MDXRenderer>{data.mdx?.body ?? ''}</MDXRenderer>
            <IntroButton to={`/intro/background`}>{t(`intro.button`)}</IntroButton>
          </IntroText>
        </StyleContainer>
        <StyledGoToMapButton />
      </Page>
    </React.Fragment>
  )
}

export const query = graphql`
  query Home($locale: String) {
    mdx(frontmatter: { title: { eq: "Explore" } }, fields: { locale: { eq: $locale } }) {
      id
      body
      fields {
        locale
        subject
      }
      frontmatter {
        title
      }
    }
  }
`

export default IndexPage
